<template>
  <sign-page
    title-text="奖励信息管理"
    :request="request"
    :column-list="columnList"
    table-size="large"
    @dataFileUpdate="dataFileUpdate"
    :form-parms-add="formParmsAdd"
    :tableActions="tableActions"
    :dealSearchParm="dealSearchParm"
    :dealFormData="dealFormData"
    :form-parms-update="formParmsUpdate"
    :tableActionsFixed="true"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  rewPunRequest as request,
} from '../../api'

import {
  loadData,
  getSelectWorkerList,
  loadSelectWorkerList
} from '../../auth_lib'

export default {
  created () {
    if (this.$authFunsProxy.add || this.$authFunsProxy.edit) {
      loadSelectWorkerList(this)
    }
  },
  methods: {
    async loadData (parm) {
      return await loadData(request, this, parm)
    },
    async dataFileUpdate (parm, pageVm) {
      await request.update(pageVm.chooseData.id, {
        fileIds: parm ? parm.join(',') : ''
      })
      pageVm.loadData()
    },
    dealSearchParm () {
      return {
        type: 'rew'
      }
    },
    dealFormData (data) {
      data.type = 'rew'
      return data
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'data_file',
        label: '附件'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'select',
          selectDatas: getSelectWorkerList(this),
          label: '所属员工',
          key: 'workerId',
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '奖励类型',
          selectDatas: this.$store.getters.selectDatas['rew_type_list'],
          key: 'title',
          check: {
            required: true
          }
        },
        {
          type: 'input',
          label: '奖励名称',
          key: 'pzwj',
          check: {
            required: true
          }
        },
        {
          type: 'datePicker',
          label: '奖励批准日期',
          key: 'pzsj'
        },
        {
          type: 'input',
          label: '奖励批准机关名称',
          key: 'pzdw'
        },
        {
          type: 'select',
          label: '授予荣誉称号的级别',
          selectDatas: this.$store.getters.selectDatas['rew_level_list'],
          key: 'level'
        },
        {
          type: 'input',
          label: '奖励原因',
          key: 'reson'
        },
        {
          type: 'datePicker',
          label: '奖励撤销日期',
          key: 'jcsj'
        },
        {
          type: 'input',
          label: '奖励撤销原因',
          key: 'jcyy'
        },
        {
          type: 'input',
          label: '奖励文件文号',
          key: 'pzwjh'
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList () {
      let data = [{
        title: '员工姓名',
        field: 'workerName',
        sort: true
      },
      {
        title: '员工工号',
        field: 'workerCode',
        sort: true
      },
      {
        title: '身份证号',
        field: 'idNo',
        sort: true,
        width: 150
      },
      {
        title: '奖励类型',
        field: 'title',
        sort: true,
        filters: this.$store.getters.selectDatas['rew_type_list'].map(v => {
          return {
            label: v.label,
            value: v.label
          }
        })
      },
      {
        title: '奖励名称',
        field: 'pzwj',
        sort: true
      },
      {
        title: '奖励批准日期',
        field: 'pzsj',
        sort: true,
        width: 200,
        dataType: Date,
        filterRange: true,
        render: (h, rowData) => {
          return h('div', rowData && rowData.pzsj ? rowData.pzsj.slice(0, 10) : '-')
        },
        text: (rowData) => {
          return rowData && rowData.pzsj ? rowData.pzsj.slice(0, 10) : '-'
        }
      },
      {
        title: '奖励批准机关名称',
        field: 'pzdw',
        sort: true
      },
      {
        title: '授予荣誉称号的级别',
        field: 'level',
        sort: true,
        filters: this.$store.getters.selectDatas['rew_level_list'].map(v => {
          return {
            label: v.label,
            value: v.label
          }
        })
      },
      {
        title: '奖励原因',
        field: 'reson',
        sort: true
      },
      {
        title: '奖励文件文号',
        field: 'pzwjh',
        sort: true
      },
      {
        title: '奖励撤销日期',
        field: 'jcsj',
        sort: true,
        dataType: Date,
        width: 200,
        filterRange: true,
        render: (h, rowData) => {
          return h('div', rowData && rowData.jcsj ? rowData.jcsj.slice(0, 10) : '-')
        },
        text: (rowData) => {
          return rowData && rowData.jcsj ? rowData.jcsj.slice(0, 10) : '-'
        }
      },
      {
        title: '奖励撤销原因',
        sort: true,
        field: 'jcyy'
      }]
      return data
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      })
    }
  }
}
</script>
